import React from 'react';

import NewsArticle from '../../../components/news-article/news-article.component'

import { LATEST_RATE_SHEET } from '../../../data/latest-rate-sheet.data';

const Article = () => (
  <NewsArticle>
    <h1 className='page-title'>Changes to our mortgage products</h1>
    <p>With effect from Wednesday 13 April 2022 we will be making the following changes to our range:</p>
    <p><strong>Summary of changes</strong></p>
    <p><strong>Residential Existing Customer Switching Range</strong></p>
    <ul>
      <li>2 Year Fixed Fee Saver at 60% LTV has decreased</li>
      <li>3 Year Fixed Standard at 85% and 90% LTV have decreased</li>
      <li>3 Year Fixed Fee Saver at 90% LTV has decreased</li>
      <li>5 Year Fixed Standard at 85%, 90% and 95% LTV have decreased</li>
      <li>5 Year Fixed Fee Saver at 90% and 95% LTV have decreased</li>
      <li>5 Year Fixed Premier Exclusive at 85% and 90% LTV have decreased</li>
      <li>2 Year Tracker Standard at 60%, 70% and 75% LTV have decreased</li>
    </ul>
    <p><strong>Residential Existing Customer Borrowing More Range</strong></p>
    <ul>
      <li>2 Year Fixed Fee Saver at 60% LTV has decreased</li>
      <li>3 Year Fixed Standard at 85% and 90% LTV have decreased</li>
      <li>3 Year Fixed Fee Saver at 90% LTV has decreased</li>
      <li>5 Year Fixed Standard at 85% and 90% LTV have decreased</li>
      <li>5 Year Fixed Fee Saver at 90% LTV has decreased</li>
      <li>5 Year Fixed Premier Exclusive at 85% and 90% LTV have decreased</li>
      <li>2 Year Tracker Standard at 60%, 70% and 75% LTV have decreased</li>
    </ul>
    <p><strong>Residential Remortgage Range</strong></p>
    <ul>
      <li>2 Year Fixed Standard at 60%, 70%, 75%, 80% and 85% LTV have increased</li>
      <li>2 Year Fixed Fee Saver at 60%, 70% and 75% LTV have decreased</li>
      <li>3 Year Fixed Standard at 60%, 70%, 75%, 80% and 85% LTV have decreased</li>
      <li>3 Year Fixed Fee Saver at 60% LTV has</li>
      <li>5 Year Fixed Standard at 60%, 70%, 75%, 80% and 85% LTV have decreased</li>
      <li>5 Year Fixed Fee Saver at 60% LTV has decreased</li>
      <li>5 Year Fixed Premier Exclusive at 60%, 70%, 75%, 80% and 85% LTV have decreased</li>
      <li>2 Year Tracker Standard at 60%, 70% and 75% LTV have decreased</li>
    </ul>
    <p><strong>Residential First Time Buyer Range</strong></p>
    <ul>
      <li>2 Year Fixed Standard at 60%, 70%, 75%, 80%, 85% and 90% LTV have decreased</li>
      <li>2 Year Fixed Fee Saver at 60%, 70%, 75%, 80%, 85% and 90% LTV have decreased</li>
      <li>3 Year Fixed Standard at 60%, 70%, 75%, 80%, 85% and 90% LTV have decreased</li>
      <li>3 Year Fixed Fee Saver at 90% LTV has decreased</li>
      <li>5 Year Fixed Standard at 60%, 70%, 75%, 80%, 85%, 90% and 95% LTV have decreased</li>
      <li>5 Year Fixed Fee Saver at 90% and 95% LTV have decreased</li>
      <li>5 Year Fixed Premier Exclusive at 60%, 70%, 75%, 80%, 85% and 90% LTV have decreased</li>
      <li>2 Year Tracker Standard at 60%, 70% and 75% LTV have decreased</li>
    </ul>
    <p><strong>Residential Home Mover Range</strong></p>
    <ul>
      <li>2 Year Fixed Standard at 60%, 70%, 75%, 80%, 85% and 90% LTV have decreased</li>
      <li>2 Year Fixed Fee Saver at 60%, 70%, 75%, 80%, 85% and 90% LTV have decreased</li>
      <li>3 Year Fixed Standard at 60%, 70%, 75%, 80%, 85% and 90% LTV have decreased</li>
      <li>3 Year Fixed Fee Saver at 90% LTV has decreased</li>
      <li>5 Year Fixed Standard at 60%, 70%, 75%, 80%, 85%, 90% and 95% LTV have decreased</li>
      <li>5 Year Fixed Fee Saver at 90% and 95% LTV have decreased</li>
      <li>5 Year Fixed Premier Exclusive at 60%, 70%, 75%, 80%, 85% and 90% LTV have decreased</li>
      <li>2 Year Tracker Standard at 60%, 70% and 75% LTV have decreased</li>
    </ul>
    <p><strong>UK Buy to Let Remortgage Range</strong></p>
    <ul>
      <li>2 Year Fixed Fee Saver at 60% and 65% LTV have decreased</li>
      <li>5 Year Fixed Fee Saver at 60% LTV has decreased</li>
    </ul>
    <p><strong>UK Buy to Let Purchase Range</strong></p>
    <ul>
      <li>2 Year Fixed Standard at 65% LTV has decreased</li>
      <li>2 Year Fixed Fee Saver at 65% LTV has decreased</li>
      <li>5 Year Fixed Standard at 65% LTV has decreased</li>
      <li>5 Year Fixed Fee Saver at 60%, 65% and 75% LTV have decreased</li>
    </ul>
    <p>We are introducing a range of three year fixed rate products within our 95% LTV mortgage range which will include both standard and fee saver options. The range will be available to Home Movers, First Time Buyers and Existing Mortgage Customers Switching Rate. Customers looking for 95% LTV lending will no longer need to meet the Mortgage Guarantee Scheme criteria and can instead apply in line with updated credit policy. </p>
    <p>An updated mortgage rate sheet will be published on Wednesday 13 April 2022 to reflect these changes.</p>
    <p><a title="This PDF opens in a new window" target="_blank" href={LATEST_RATE_SHEET.href}>Link to our latest rate sheet</a></p>
    

  </NewsArticle>
)
export default Article